export const IdeasData = [
  { 
    time: 'Prio', 
    title: 'PowerFences', 
    description: 'Geolocation, Geofencing, Indoor Navi, Guided tours, Team Events, Games, Service Guides and Registration, IoT...\n'+
                 'Mobile App',
    icon: '',
    imageUrl: '',
    link: ''
  },
  { 
    time: 'Prio', 
    title: 'WeAgree', 
    description: 'Portal for overenskomster og vedtægter\n'+
                 'Vedligehold og administration af ændringsforslag i flere niveauer organisatorisk\n' + 
                 'Web Applikation',
    icon: '',
    imageUrl: '',
    link: 'https://weagree.powerfences.com/weagree-presentation.pdf'
  }
]