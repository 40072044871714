import React, { Component } from 'react';
import { StyleSheet, View, Text, Alert } from 'react-native';
import Timeline from "react-native-timeline-flatlist";
import { IdeasData } from "../data/Ideas";
import * as Linking from 'expo-linking';

const openLink = (link) => {
  if (link.length > 0) {
    Linking.openURL(link);
  }
}

export const IdeasScreen = ({ navigation }) => {
  return (
    <View style={styles.container}>
      {/* <Text style={styles.header}>Produkt-ideer</Text> */}
      <Timeline
        style={styles.list}
        data={IdeasData}
        circleSize={20}
        innerCircle={'icon'}
        circleColor='#9C8711'
        lineColor='lightgray'
        timeContainerStyle={{ minWidth: 47, marginTop: -5 }}
        detailContainerStyle={{ marginTop: -10, marginBottom: 15 }}
        timeStyle={{ textAlign: 'center', backgroundColor: 'darkgray', color: 'white', padding: 5, borderRadius: 13 }}
        titleStyle={{ color: '#9C8711', fontSize: 18 }}
        descriptionStyle={{ color: '#2C2708' }}
        options={{
          style: { paddingTop: 5 }
        }}
        isUsingFlatlist={true}
        onEventPress={(event) => {
            openLink(event.link);
          }
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    //paddingTop: 65,
    backgroundColor: 'white'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#2C2708'
  },
  list: {
    flex: 1,
    marginTop: 20,
  },
});