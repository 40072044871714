export const EventData = [
  { 
    time: '2022\n2004', 
    title: 'OutlookPrintout', 
    description: 'One-pager overblik over dagens opgaver, møder mm.\n'+
                 'Brugere: Statsministeriet, Udenrigstministeriet, Overborgmesteren i Kbh\n' + 
                 '2022: Solgt til Statens It',
    icon: '',
    imageUrl: '',
    link: 'https://statens-it.dk/'
  },
  { 
    time: '2021\n2019', 
    title: 'PowerGuides', 
    description: 'PowerGuides ApS\n' + 
                 'Chrome Extension til "Learning in the flow of work"\n' + 
                 'Kunde: Demant A/S\n' + 
                 '2021: Solgt til Fuse Universal', 
    icon: '',
    imageUrl: '',
    link: 'https://chrome.google.com/webstore/detail/fuse-flow/hkclahnmhniojcpfpkmolmdknbpnaccp?hl=en-GB'
  },
  { 
    time: '2017\n2015', 
    title: 'Mintegrator', 
    description: 'Mintegrator ApS\nUnik digital medie- og annonceplatform\n' + 
                 'Omsætning: ~50K\n' + 
                 '(Jeg trak mig ud efter 2,5 år. Virksomheden senere lukket ned)', 
    icon: '',
    imageUrl: '',
    link: ''
  },
  { 
    time: '2013\n2007', 
    title: 'Gantt for Outlook', 
    description: 'Projekt og opgavestyring i Outlook', 
    icon: '',
    imageUrl: '',
    link: 'https://youtu.be/UfEgH01_ojQ'
  },  
  { 
    time: '2010\n2008', 
    title: 'Timengo Sport', 
    description: 'Timengo A/S\n' + 
                 'Event og medlemshåndtering til sportsklubber\n' + 
                 'Omsætning på produktet: ingen\n' + 
                 '(Jeg trak mig ud efter 3 år. Virksomheden lever i dag af konsulentydelser)', 
    icon: '',
    imageUrl: ''
  },
  { 
    time: '2007\n2006', 
    title: 'ShiftPlanning', 
    description: 'Planlægning af skifteholdsarbejde\n' + 
                 'Solgt til en kunde, Egmont Magasiner', 
    icon: '',
    imageUrl: '',
    link: ''
  },
  { 
    time: '2008\n2005', 
    title: 'Viden- + Bookingsystem', 
    description: 'Vidensystem, bookingsystem m. MS Exchange og Outlook integration\n' + 
                 'Kunde: Helsingør kommune', 
    icon: '',
    imageUrl: '',
    link: ''
  },
  { 
    time: '2007\n2004', 
    title: 'OKweb', 
    description: 'Portal for overenskomster og vedtægter\n'+
                 'Vedligehold og administration af ændringsforslag\n' + 
                 'Web Applikation\n' +
                 '2004: Leverance til CO-industri',
    icon: '',
    imageUrl: '',
    link: ''
  },  
  { 
    time: '2007\n1998', 
    title: 'Sagsstyringssystem', 
    description: 'Sags- og aktivitetsstyring, dokumenthåndtering\n' + 
                 'Kunde: CO-industri', 
    icon: '',
    imageUrl: '',
    link: ''
  },
  { 
    time: '2004\n2001', 
    title: 'WebTeam Central', 
    description: 'Web-baseret gruppekalender m Exchange Integration\n' + 
                 'Salgspartner: Add-on Products, Vejle\n' +
                 'Kåring: Bedste Exchange Produkt på TechEd\n' +
                 'Kunde highlights: Danske ministerier, danske og udenlandske virksomheder, US undervisningsministerium, US forsvarsministerum', 
    icon: require('../assets/award32.png'),
    imageUrl: '',
    link: 'https://codepower.biz/img/press/article1.jpeg'
  },
  { 
    time: '1999\n1998', 
    title: 'Global Calendar Server', 
    description: 'Kalender-synkronisering med MS Exchange Server integration', 
    icon: '',
    imageUrl: '',
    link: ''
  },
  { 
    time: '1999\n1997', 
    title: 'Executive Insights', 
    description: 'On-Line Analhytic Processing (OLAP) tillægsløsning. Værktøjet sætter på en enkel måde brugeren i stand til at bearbejde store datamængder uden at skulle benytte sig af programmering og dyre multidimensionelle servere\n' + 
                 '1999: Solgt til Navision Softare A/S', 
    icon: '',
    imageUrl: '',
    link: 'https://codepower.biz/img/press/article6.jpeg'
  },
  { 
    time: '1997', 
    title: 'Navision Development Partner', 
    description: 'Certificeret Navision Development Partner for Navision Software A/S', 
    icon: require('../assets/award32.png'),
    imageUrl: '',
    link: ''
  },
  { 
    time: '1997\n1995', 
    title: 'Activity Map', 
    description: 'Mindmapping for Windows\n' + 
                 '1997: Solgt til Time/system International a/s' +
                 'Kåring: Bedste brugergrænseflade på IT messe (nr. 2: Windows 95)', 
    icon: require('../assets/award32.png'),
    imageUrl: '',
    link: 'https://codepower.biz/img/press/article7.jpeg'
  },
  { 
    time: '1995', 
    title: 'Danmarksmester', 
    description: 'Kåring: Danmarksmester i programmering\n' + 
                 'Vurdering: Helstøbt løsning med en brugergrænseflade, der ville kunne anvendes i praksis\n' + 
                 'Præmie: Rejse til den internationale konference "Visual Basic Insiders Technical Summit" i San Francisco, USA', 
    icon: require('../assets/award32.png'),
    imageUrl: '',
    link: 'https://codepower.biz/img/press/article8.jpeg'
  }
]