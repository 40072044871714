import { StatusBar } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { StyleSheet, Text, View } from 'react-native';
import { HomeScreen } from "./screens/HomeScreen";
import { IdeasScreen } from "./screens/IdeasScreen";
import { MaterialCommunityIcons } from '@expo/vector-icons';

const Tab = createBottomTabNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Tab.Navigator>
        <Tab.Screen 
          name="My Timeline" 
          component={HomeScreen} 
          //options={{ title: 'Those were the days' }} 
          options={{
            tabBarLabel: 'Timeline of the Past',
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="timeline-clock-outline"
                color={color}
                size={size}
              />
            ),
          }}          
        />
        <Tab.Screen 
        name="Future" 
        component={IdeasScreen} 
        // options={{ title: 'Future Cash Cow?' }} 
        options={{
            tabBarLabel: 'Future Stars',
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="puzzle-star-outline"
                color={color}
                size={size}
              />
            ),
          }}              
      />
      </Tab.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
